<template>
  <van-form @submit="onSubmit">
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            v-model="form.nickname"
            label="姓名"
        />
        <van-field
            readonly
            v-model="form.email"
            label="邮箱"
        />
        <van-field
            readonly
            v-model="form.wx_number"
            label="微信号"
        />
      </van-cell-group>

    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            label="擅长领域"
        >
          <template #input>
            {{ form.shan_chang_name }}
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            v-model="form.name"
            label-width="120"
            label="曾就职公司/职位"
        >
          <template #input>
            <div>
              <div class="jin-li" v-for="vo in form.json_gcs_gzjl">
                {{ vo.gong_si_name }}【{{ vo.zhi_wei }}】
              </div>
              <div class="jin-li" v-for="vo in form.json_gcs_gzjl">
                {{ vo.gong_si_name }}【{{ vo.zhi_wei }}】
              </div>
            </div>
          </template>
        </van-field>
      </van-cell-group>

    </div>
    <div class="form-btn">
      <van-button round block :to="{name:'UserCompanyInfoEdit'}">
        编辑
      </van-button>
    </div>
  </van-form>
</template>

<script>
export default {
  name: 'geRenFuWuShangInfo',
}
</script>
<script setup>
import {ref, onMounted} from 'vue'
import http from "../../../api/http";
import XEUtils from "xe-utils";
import _ from 'lodash'

let form = ref({})

function onSubmit() {

}

onMounted(() => {
  getData()
})

function getData() {
  http.get('/gzhphp/user/getGeRenGongChengShi').then(da => {
    if (da.code === 1) {
      if (XEUtils.isObject(da.data)) {
        _.set(da,'data.shan_chang_name',_.split(_.get(da, 'data.shan_chang_name'), ',').join('、'))
        form.value = da.data
      }

    }
  })
}
</script>

<style scoped>
.jin-li {
  margin-bottom: 5px;
  width: 100%;
}
</style>
