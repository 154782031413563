<template>
  <nav-header :title="title"/>
  <!--  客户  -->
  <company-info v-if="userInfo.dang_qian_jiao_se === '客户'"/>
  <!--  服务商  -->
  <ServiceProviderInfo v-else-if="userInfo.fws_type === '企业'"/>
  <ge-ren-fu-wu-shang-info v-else-if="userInfo.fws_type === '个人'"/>
  <div style="height: 20px;"></div>
</template>

<script>
import ServiceProviderInfo from "./public/ServiceProviderInfo";
import http from "../../api/http";
import CompanyInfo from "./public/CompanyInfo";
import GeRenFuWuShangInfo from "./public/geRenFuWuShangInfo";
import NavHeader from "../../components/top/NavHeader";

export default {
  name: 'EditCompanyInfo',
  components: {NavHeader, GeRenFuWuShangInfo, CompanyInfo, ServiceProviderInfo},
  data() {
    return {
      userInfo: {},
    }
  },
  mixins: [],
  mounted() {
    http.get("/gzhphp/user/getUserInfo").then(da => {
      if (da.code === 1) {
        this.userInfo = da.data
      }
    })
  },
  methods: {},
  watch: {},
  computed: {}
}
</script>
<script setup>
import {computed} from 'vue'
import {userBasis} from "./public/Basis";
import $ from 'jquery'
let {jiaoSe} = userBasis()
let title = computed(() => {
  let val = '服务商信息'
  if (jiaoSe.value === '客户') {
    val = '客户信息'
  }
  $('title').html(val)
  return val
})
</script>

<style scoped>
.edit-btn {

}
</style>
